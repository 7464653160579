import Image from "next/image";
import { useConnect } from "wagmi";
import { useRouter } from "next/router";
import { GetServerSideProps } from "next";
import { getServerSession } from "next-auth";

import { onlyMounted } from "@components/web3";
import { generateAuthOptions } from "@pages/api/auth/[...nextauth]";

import EmailIcon from "@public/img/email.svg";
import MetaMaskIcon from "@public/img/metamask.svg";
import WalletConnectIcon from "@public/img/walletConnect.svg";

const connectorIcons: Record<
  string,
  { src: string; width: number; height: number }
> = {
  injected: MetaMaskIcon,
  walletConnect: WalletConnectIcon,
  web3auth: EmailIcon,
};

const connectorLabels: Record<string, string> = {
  Injected: "MetaMask",
  WalletConnect: "WalletConnect",
  Web3Auth: "Connect with email",
};

const Login = onlyMounted(() => {
  const router = useRouter();
  const { connect, connectors } = useConnect({
    onSuccess: (data) => {
      router.push(`/login/${data.account}/complete`);
    },
  });

  const filteredConnectors = connectors.filter((connector) => {
    const icon = connectorIcons[connector.id];

    return !!icon;
  });

  return (
    <div className="grid h-full grid-cols-1 md:grid-cols-2">
      <div className="hidden bg-jacarta-700 md:block"></div>
      <div className="flex items-center justify-center bg-white dark:bg-jacarta-900">
        <div className="w-full sm:w-[424px]">
          <h1 className="text-center text-4xl font-semibold text-jacarta-700 dark:text-white">
            Sign in
          </h1>

          <h2 className="text-center text-lg text-jacarta-500 dark:text-jacarta-300">
            Choose one of available wallet providers or create a new wallet.{" "}
            <span className="font-bold text-accent">What is a wallet?</span>
          </h2>

          <h3 className="py-4 text-center text-lg font-semibold">
            Connect your wallet
          </h3>

          <p className="divide-y-2 py-3 text-center text-sm font-extralight text-gray-700">
            Select your desired wallet
          </p>

          {filteredConnectors.map((connector) => {
            const icon = connectorIcons[connector.id];

            return (
              <div
                key={connector.id}
                role={"button"}
                className="p2 flex flex-col items-center justify-center py-5  hover:bg-slate-200"
                onClick={() => {
                  connect({ connector });
                }}
              >
                <h3 className="mb-3 text-md text-zinc-800">
                  {connectorLabels[connector.name] || connector.name}
                </h3>

                {icon && (
                  <div className="w-20">
                    <Image src={icon} alt={`${connector.name} logo`} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
});
export const getServerSideProps: GetServerSideProps = async (context) => {
  try {
    const session = await getServerSession(
      context.req,
      context.res,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      generateAuthOptions(context.req)
    );

    console.log({
      session
    })

    if (session) {
      return {
        redirect: {
          destination: "/",
          permanent: false,
        },
      };
    }

    return {
      props: {}
    }
    
  } catch (err) {
    console.error(`Errored`, err);
    return {
      notFound: true,
    };
  }
};

export default Login;
